<template>
  <div class="card" :class="{ 'modal-card': modal }">
    <div v-if="displayRightContent" class="right-content">
      <h3>{{ rightContentText }}</h3>
    </div>
    <div
      v-if="title !== ''"
      :class="{ 'card-top': true, 'medcenter-modal': patientType === 'medcenter', 'modal-top': modal }"
    >
      <BaseHeader v-if="title !== ''" type="h2" no-margin>
        {{ title }}
      </BaseHeader>
      <slot name="action" />
      <i v-if="modal" class="el-icon-close" @click="$emit('toggleModal')" />
    </div>
    <div :class="{ titled: title }" class="slot-content">
      <slot />
    </div>
    <slot name="loader" />
  </div>
</template>

<script>
import BaseHeader from "@/components/common/BaseHeader.vue";

export default {
  name: "BaseCard",
  components: {
    BaseHeader,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    patientType: {
      type: String,
      required: false,
      default: () => {
        return "intellipel";
      },
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayRightContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightContentText: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.medcenter-modal {
  padding: 0px 25px;
}
.card {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  // border: 1px solid #e6e6e6;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.card-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}
.modal-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titled {
  margin-top: 25px;
}
.el-icon-close {
  cursor: pointer;
  font-size: 24px;
  margin-left: 25px;
  &:hover {
    color: $orange;
  }
}
.modal-card {
  max-height: 80%;
  overflow-y: scroll;
}
.modal-card::-webkit-scrollbar {
  display: none;
}
.right-content {
  float: right;
}
</style>
