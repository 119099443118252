<template>
  <div
    class="carousel"
  >
    <div
      v-if="positionX !== 0"
      class="action back"
      @click="moveCarousel('back')"
    >
      <i class="i el-icon-caret-left" />
    </div>
    <div
      ref="carousel"
      class="carousel-view"
    >
      <div
        ref="container"
        class="item-container"
      >
        <router-link
          v-for="product in carouselProducts"
          :key="product.id"
          :to="productRoute(product)"
          class="router-link"
        >
          <ProductCard
            v-loading="loading"
            class="carousel-card"
            :product="product"
            :is-market-place="true"
            :my-stock-room-product="false"
          />
        </router-link>
      </div>
    </div>
    <div
      v-if="rightArrow"
      class="action forward"
      @click="moveCarousel('forward')"
    >
      <i class="i el-icon-caret-right" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { translateX } from '@/utils/animations';
import ProductCard from '@/components/marketplace/common/ProductCard.vue';

export default {
  name: 'BaseCarousel',
  components: {
    ProductCard,
  },
  props: {
    categorySlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      positionX: 0,
      rightArrow: true,
    };
  },
  computed: {
    ...mapGetters({
      carousels: 'marketplaceCarousel/getCarousels',
    }),
    carouselProducts() {
      return this.carousels[this.categorySlug];
    },
  },
  watch: {
    async categorySlug() {
      this.loading = true;
      this.$emit('loading');
      if (this.categorySlug !== undefined) {
        await this.fetch(this.categorySlug);
        this.loading = false;
        this.$emit('done');
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.$emit('loading');
    if (this.categorySlug !== undefined) {
      await this.fetch(this.categorySlug);
      this.loading = false;
      this.$emit('done');
    }
  },
  methods: {
    ...mapActions({
      fetch: 'marketplaceCarousel/fetchProductsBySlug',
    }),
    carouselWidth() {
      return this.$refs.carousel.clientWidth;
    },
    containerWidth() {
      return this.$refs.container.clientWidth;
    },
    maxOver() {
      return this.containerWidth() - this.carouselWidth();
    },
    moveCarousel(direction) {
      if (direction === 'forward' && Math.abs(this.positionX) < this.maxOver()) {
        if (this.maxOver() - Math.abs(this.positionX) < this.carouselWidth()) {
          this.positionX = this.positionX - (this.maxOver() - Math.abs(this.positionX));
          this.rightArrow = false;
          translateX(this.$refs.container, this.positionX);
          return;
        }
        this.positionX = this.positionX - this.carouselWidth();
        translateX(this.$refs.container, this.positionX);
      }
      if (direction === 'back' && this.positionX < 0) {
        if (Math.abs(this.positionX) < this.carouselWidth()) {
          this.positionX = 0;
          translateX(this.$refs.container, this.positionX);
          return;
        }
        this.rightArrow = true;
        this.positionX = this.positionX + this.carouselWidth();
        translateX(this.$refs.container, this.positionX);
      }
    },
    productRoute(p) {
      const { vin, slug, categorySlug } = p;
      return {
        name: 'Product',
        params: { vin, slug, categorySlug },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  margin: 25px auto;
  align-items: center;
}
.action {
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 16px;
  background-color: $orange;
  transition: all .2s ease;
  z-index: 1;
  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}
.carousel-view {
  display: flex;
  flex: 1;
  align-items: center;
  overflow-x: hidden;
  margin: 0 -15px;
  padding: 10px 0px;
}
.item-container {
display: flex;
position: relative;
left: 0px;
}
.router-link {
  text-decoration: none;
}
.carousel-card {
  margin: 0px 5px;
}
</style>
