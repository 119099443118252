import { render, staticRenderFns } from "./AvatarLetter.vue?vue&type=template&id=109d7813&scoped=true"
import script from "./AvatarLetter.vue?vue&type=script&lang=js"
export * from "./AvatarLetter.vue?vue&type=script&lang=js"
import style0 from "./AvatarLetter.vue?vue&type=style&index=0&id=109d7813&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "109d7813",
  null
  
)

export default component.exports