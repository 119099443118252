<template>
  <div :class="{ 'selected-avatar': user.isSelected }" class="avatar-letter">
    <p>
      {{ letter }}
    </p>
  </div>
</template>

<script>
export default {
  name: "AvatarLetter",
  props: {
    letter: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-letter {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: #f5f7fa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-blue;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  p {
    font-weight: 600;
  }
}
.selected-avatar {
  background-color: #fff3f0;
  color: #f05a28;
}
</style>
