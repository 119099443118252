<template>
  <button
    class="button"
    :class="[needsConfirm ? type : type, disabled ? 'disabled' : '']"
    :disabled="disabled"
    :loading="isLoading"
    @click.prevent="handleClick"
  >
    <i v-if="icon !== 'none'" class="icon" :class="icon" />
    <slot v-if="!needsConfirm" />
    <span v-else>
      {{ confirmMessage }}
    </span>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  components: {},
  props: {
    type: {
      type: String,
      required: false,
      default: () => {
        "";
      },
    },
    icon: {
      type: String,
      default: "none",
    },
    disabled: Boolean,
    isLoading: Boolean,
    confirm: Boolean,
    confirmMessage: {
      type: String,
      default: "Are you sure?",
    },
  },
  data() {
    return {
      needsConfirm: false,
    };
  },
  methods: {
    handleClick() {
      if (this.confirm && !this.needsConfirm) {
        this.needsConfirm = true;
        return;
      }
      this.$emit("click");
      this.needsConfirm = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  color: $orange;
  border: none;
  font-size: 14px;
  padding: 0px 7px;
  border-radius: 4px;
  transition: all 0.2s ease;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid transparent;
  min-width: fit-content;
  .icon {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}
.form {
  color: #fff;
  background-color: $orange;
  font-weight: 600;
  width: 100%;
  height: 40px;
  &:hover {
    background-color: $orange;
    opacity: 0.8;
  }
}
.cta {
  height: 40px;
  background-color: $orange;
  font-weight: 600;
  color: #fff;
  &:hover {
    background-color: $orange;
    opacity: 0.8;
  }
}
.common {
  color: #fff;
  background-color: #384559;
}
.secondary {
  height: 40px;
  color: $orange;
  background-color: #fff;
  border: 1px solid $orange;
  &:hover {
    background-color: $orange;
    color: white;
  }
}
.secondary-small {
  height: 25px;
  color: $orange;
  background-color: #fff;
  border: 1px solid $orange;
  &:hover {
    background-color: $orange;
    color: white;
  }
}

.secondary-admin {
  height: 25px;
  color: #ed8f8f;
  background-color: #fff;
  border: 1px solid #ed8f8f;
  &:hover {
    background-color: #ed8f8f;
    color: white;
  }
}

.no-text {
  .icon {
    margin-right: 0px;
  }
}
.blue {
  background-color: $dark-blue;
  &:hover {
    background-color: $dark-blue;
    opacity: 0.8;
  }
}
.disabled {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
