<template>
  <div>
    <h1
      v-if="type === 'h1'"
      :class="{ 'no-margin': noMargin, 'white': white, 'hero': hero }"
    >
      <slot />
    </h1>
    <h2
      v-if="type === 'h2'"
      :class="{ 'no-margin': noMargin}"
    >
      <slot />
    </h2>
    <h3
      v-if="type === 'h3'"
      :class="{ 'no-margin': noMargin}"
    >
      <slot />
    </h3>
    <h4
      v-if="type === 'h4'"
      :class="{ 'no-margin': noMargin}"
    >
      <slot />
    </h4>
    <h5
      v-if="type === 'h5'"
      :class="{ 'no-margin': noMargin}"
    >
      <slot />
    </h5>
    <h6
      v-if="type === 'h6'"
      :class="{ 'no-margin': noMargin}"
    >
      <slot />
    </h6>
  </div>
</template>

<script>
export default {
  name: 'BaseHeading',
  props: {
    type: {
      type: String,
      default: 'h1',
    },
    noMargin: {
      type: Boolean,
    },
    white: {
      type: Boolean,
    },
    hero: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 24px;
    margin: 24px 0;
    font-weight: 600;
    color: $dark-blue;
  }
  h2 {
    font-size: 20px;
    margin: 20px 0;
    line-height: 20px;
    font-weight: 600;
    color: $dark-blue;
  }
  h3 {
    font-size: 18px;
    margin: 18px 0;
    line-height: 18px;
    font-weight: 600;
    color: $dark-blue;
  }
  h4 {
    font-size: 16px;
    margin: 16px 0;
    line-height: 16px;
    font-weight: 600;
    color: $dark-blue;
  }
  h5 {
    font-size: 14px;
    margin: 14px 0;
    line-height: 14px;
    font-weight: 600;
    color: $dark-blue;
  }
  h6 {
    font-size: 12px;
    margin: 12px 0;
    line-height: 12px;
    font-weight: 600;
    color: $dark-blue;
  }
  .no-margin {
    margin: 0;
  }
  .white {
    color: #fff;
  }
  .hero {
    font-size: 40px;
  }
</style>
